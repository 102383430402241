import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/nosotros',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/servicios',
    name: 'Services',
    component: () => import(/* webpackChunkName: "services" */ '../views/Services.vue')
  },
  {
    path: '/equipos',
    name: 'Equipment',
    component: () => import(/* webpackChunkName: "equipment" */ '../views/Equipment.vue')
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/servicios/:product',
    name: 'Service',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue'),
    props: true
  },
  {
    path: '/equipos/:product',
    name: 'Machine',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product.vue'),
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
