<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :aria-labelledby="name"
    role="presentation"
    :viewBox="viewBox"
    class="inline-block fill-current"
  >
    <title :id="name" lang="en">{{ name }}</title>
    <slot />
  </svg>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true },
    viewBox: { type: String, required: true }
  }
}
</script>
