<template>
  <BaseSVG name="Logo Andinor" width="80" height="45" view-box="0 0 80 45">
    <path
      d="M17.9296 32.1384H15.6729L15.3594 33.1855H13.3304L15.7498 26.8353H17.9178L20.3342 33.1855H18.252L17.9296 32.1384ZM17.5185 30.7631L16.8086 28.4797L16.1047 30.7631H17.5185Z"
      :fill="color.text"
    />
    <path
      d="M20.5472 33.3333H18.1426L17.8202 32.2863H15.7824L15.4688 33.3333H13.1145L15.6463 26.6874H18.0184L20.5472 33.3333ZM18.3615 33.0376H20.1183L17.8143 26.9831H15.8504L13.5463 33.0376H15.2529L15.5664 31.9905H18.042L18.3615 33.0376ZM17.7196 30.911H15.9066L16.8087 27.9799L17.7196 30.911ZM16.3059 30.6152H17.3174L16.8087 28.9796L16.3059 30.6152Z"
      :fill="color.text"
    />
    <path
      d="M22.1029 26.8353H23.9574L26.3798 30.349V26.8353H28.252V33.1855H26.3798L23.9692 29.6983V33.1855H22.1V26.8353H22.1029Z"
      :fill="color.text"
    />
    <path
      d="M28.4028 33.3333H26.3028L24.1201 30.1716V33.3333H21.955V26.6874H24.0372L26.2319 29.8728V26.6874H28.3999V33.3333H28.4028ZM26.4596 33.0376H28.107V26.9831H26.5306V30.8252L23.8834 26.9831H22.2537V33.0376H23.8272V29.2251L26.4596 33.0376Z"
      :fill="color.text"
    />
    <path
      d="M30.7246 26.8353H33.6794C34.2621 26.8353 34.7323 26.9122 35.0902 27.0689C35.4481 27.2257 35.7439 27.4475 35.9775 27.7403C36.2112 28.0331 36.3827 28.3703 36.4862 28.7578C36.5927 29.1452 36.643 29.5564 36.643 29.9882C36.643 30.6655 36.5661 31.192 36.4093 31.5676C36.2526 31.9432 36.0367 32.2538 35.7587 32.5081C35.4806 32.7625 35.1819 32.9281 34.8625 33.0139C34.4277 33.1293 34.0314 33.1884 33.6764 33.1884H30.7217V26.8353H30.7246ZM32.7122 28.2727V31.7421H33.2002C33.6143 31.7421 33.9101 31.6977 34.0875 31.606C34.2621 31.5144 34.4011 31.3576 34.4987 31.1299C34.5992 30.9021 34.6466 30.5354 34.6466 30.0266C34.6466 29.3523 34.5342 28.8938 34.3123 28.6454C34.0905 28.3969 33.7208 28.2727 33.2061 28.2727H32.7122Z"
      :fill="color.text"
    />
    <path
      d="M33.6794 33.3333H30.5768V26.6874H33.6794C34.2798 26.6874 34.7738 26.7702 35.1494 26.9329C35.528 27.0985 35.8474 27.3381 36.0959 27.6486C36.3413 27.9562 36.5218 28.3171 36.6312 28.7193C36.7406 29.1157 36.7939 29.5445 36.7939 29.9882C36.7939 30.6832 36.7111 31.2334 36.5484 31.6238C36.3857 32.0172 36.1521 32.3514 35.8592 32.6176C35.5635 32.8867 35.244 33.0671 34.9009 33.1559C34.4543 33.2742 34.0432 33.3333 33.6794 33.3333ZM30.8726 33.0376H33.6794C34.0196 33.0376 34.4041 32.9814 34.827 32.869C35.1228 32.7921 35.4008 32.6324 35.6611 32.3987C35.9214 32.1621 36.1284 31.8634 36.2763 31.5114C36.4242 31.1565 36.4981 30.6448 36.4981 29.9882C36.4981 29.5682 36.4478 29.1689 36.3443 28.7962C36.2437 28.4295 36.084 28.1071 35.8622 27.832C35.6433 27.5599 35.3653 27.3499 35.0311 27.205C34.6939 27.0571 34.2384 26.9831 33.6794 26.9831H30.8726V33.0376ZM33.2003 31.89H32.5644V28.1248H33.2091C33.7652 28.1248 34.1734 28.2668 34.4248 28.5478C34.6732 28.8258 34.7945 29.3109 34.7945 30.0296C34.7945 30.562 34.7412 30.9435 34.6318 31.192C34.5194 31.4493 34.3567 31.6327 34.1526 31.7391C33.9574 31.8397 33.6439 31.89 33.2003 31.89ZM32.8601 31.5942H33.2003C33.6853 31.5942 33.9131 31.5291 34.0196 31.4729C34.1645 31.399 34.2798 31.2659 34.3656 31.0677C34.4573 30.8607 34.5017 30.5087 34.5017 30.0237C34.5017 29.3907 34.4011 28.9589 34.2059 28.74C34.0136 28.5271 33.6794 28.4176 33.2091 28.4176H32.8601V31.5942Z"
      :fill="color.text"
    />
    <path
      d="M38.8909 26.8353H40.8844V33.1855H38.8909V26.8353Z"
      :fill="color.text"
    />
    <path
      d="M41.0322 33.3333H38.743V26.6874H41.0322V33.3333ZM39.0388 33.0376H40.7365V26.9831H39.0388V33.0376Z"
      :fill="color.text"
    />
    <path
      d="M43.4457 26.8353H45.3032L47.7255 30.349V26.8353H49.6007V33.1855H47.7255L45.315 29.6983V33.1855H43.4457V26.8353Z"
      :fill="color.text"
    />
    <path
      d="M49.7486 33.3333H47.6487L45.4659 30.1716V33.3333H43.3008V26.6874H45.3831L47.5806 29.8728V26.6874H49.7516V33.3333H49.7486ZM47.8025 33.0376H49.4499V26.9831H47.8705V30.8252L45.2234 26.9831H43.5937V33.0376H45.1672V29.2251L47.8025 33.0376Z"
      :fill="color.text"
    />
    <path
      d="M51.7924 30.0148C51.7924 28.9766 52.0852 28.1692 52.6708 27.5924C53.2564 27.0157 54.0698 26.7258 55.1139 26.7258C56.1845 26.7258 57.0098 27.0098 57.5895 27.5776C58.1692 28.1455 58.459 28.9412 58.459 29.9616C58.459 30.7039 58.3318 31.3132 58.0804 31.7865C57.829 32.2597 57.4623 32.6324 56.9831 32.8956C56.504 33.1588 55.9095 33.2919 55.1937 33.2919C54.4691 33.2919 53.8687 33.1766 53.3925 32.9488C52.9163 32.7211 52.5318 32.3603 52.236 31.8663C51.9402 31.3724 51.7924 30.7572 51.7924 30.0148ZM53.7829 30.0237C53.7829 30.6655 53.9042 31.1269 54.1437 31.4049C54.3833 31.6829 54.7146 31.8249 55.1286 31.8249C55.5546 31.8249 55.8858 31.6889 56.1195 31.4138C56.3531 31.1387 56.4714 30.6477 56.4714 29.9349C56.4714 29.3375 56.3502 28.8997 56.1047 28.6247C55.8592 28.3496 55.5279 28.2106 55.1109 28.2106C54.7087 28.2106 54.3892 28.3496 54.1467 28.6306C53.9042 28.9116 53.7829 29.3759 53.7829 30.0237Z"
      :fill="color.text"
    />
    <path
      d="M55.1937 33.4428C54.4484 33.4428 53.8213 33.3215 53.3274 33.0849C52.8276 32.8453 52.4164 32.4608 52.1088 31.9432C51.8012 31.4286 51.6445 30.7779 51.6445 30.0118C51.6445 28.9382 51.955 28.0893 52.5673 27.486C53.1795 26.8826 54.0343 26.5779 55.1168 26.5779C56.223 26.5779 57.0896 26.8796 57.6959 27.4712C58.3023 28.0657 58.6099 28.9027 58.6099 29.9616C58.6099 30.7246 58.4768 31.3635 58.2135 31.8574C57.9473 32.3573 57.5599 32.7507 57.0571 33.0257C56.5572 33.3038 55.9302 33.4428 55.1937 33.4428ZM55.1168 26.8737C54.1171 26.8737 53.3304 27.1517 52.7773 27.6989C52.2242 28.2461 51.9432 29.024 51.9432 30.0148C51.9432 30.7276 52.0852 31.3251 52.3662 31.7924C52.6442 32.2597 53.0139 32.6028 53.4605 32.8187C53.913 33.0376 54.4987 33.147 55.1967 33.147C55.8829 33.147 56.4596 33.0198 56.9121 32.7684C57.3617 32.52 57.7137 32.165 57.9503 31.7184C58.1899 31.2659 58.3111 30.6773 58.3111 29.9616C58.3111 28.9855 58.0331 28.2165 57.4859 27.6812C56.9388 27.1458 56.1402 26.8737 55.1168 26.8737ZM55.1286 31.9728C54.6702 31.9728 54.3005 31.8131 54.0313 31.5025C53.7622 31.192 53.635 30.7099 53.635 30.0237C53.635 29.3345 53.7651 28.8465 54.0343 28.5359C54.3064 28.2224 54.6672 28.0657 55.1109 28.0657C55.5723 28.0657 55.942 28.2224 56.2141 28.53C56.4833 28.8317 56.6193 29.3049 56.6193 29.9379C56.6193 30.6921 56.4921 31.2067 56.2319 31.5114C55.9716 31.816 55.5989 31.9728 55.1286 31.9728ZM55.1109 28.3614C54.753 28.3614 54.472 28.4827 54.2561 28.7312C54.0373 28.9826 53.9278 29.4173 53.9278 30.0266C53.9278 30.63 54.0373 31.0618 54.2532 31.3132C54.4661 31.5617 54.753 31.6829 55.1257 31.6829C55.5102 31.6829 55.7971 31.5646 56.0041 31.3221C56.2141 31.0766 56.3206 30.6123 56.3206 29.9409C56.3206 29.3818 56.2082 28.9737 55.9923 28.7282C55.7764 28.4797 55.4895 28.3614 55.1109 28.3614Z"
      :fill="color.text"
    />
    <path
      d="M60.6803 33.1855V26.8353H63.9929C64.6081 26.8353 65.0754 26.8885 65.4008 26.992C65.7261 27.0955 65.9864 27.2878 66.1875 27.5717C66.3887 27.8557 66.4863 28.1958 66.4863 28.601C66.4863 28.953 66.4094 29.2576 66.2585 29.512C66.1077 29.7664 65.8977 29.9763 65.6315 30.1331C65.4629 30.2337 65.2292 30.3165 64.9335 30.3845C65.1701 30.4614 65.3416 30.5413 65.4511 30.6182C65.525 30.6714 65.6315 30.7808 65.7705 30.9524C65.9095 31.1239 66.0012 31.2541 66.0485 31.3458L67.0098 33.1855H64.7649L63.7031 31.2452C63.567 30.9938 63.4487 30.8311 63.3422 30.7542C63.1973 30.6566 63.0376 30.6063 62.8542 30.6063H62.6797V33.1855H60.6803ZM62.6767 29.4085H63.5138C63.6055 29.4085 63.78 29.3789 64.0403 29.3227C64.1704 29.2961 64.2798 29.231 64.3626 29.1245C64.4455 29.0181 64.4869 28.8938 64.4869 28.7548C64.4869 28.5507 64.4218 28.391 64.2887 28.2816C64.1556 28.1721 63.9101 28.116 63.5463 28.116H62.6738V29.4085H62.6767Z"
      :fill="color.text"
    />
    <path
      d="M67.2523 33.3333H64.6732L63.57 31.3162C63.4162 31.0293 63.3127 30.9169 63.2535 30.8755C63.1352 30.7956 63.005 30.7542 62.8512 30.7542H62.8246V33.3333H60.5324V26.6874H63.9929C64.6288 26.6874 65.105 26.7406 65.4452 26.8501C65.8001 26.9624 66.0899 27.1754 66.3058 27.483C66.5218 27.7876 66.6312 28.1633 66.6312 28.5981C66.6312 28.9766 66.5484 29.3079 66.3827 29.5859C66.2171 29.861 65.9894 30.0887 65.7025 30.2603C65.6108 30.3135 65.5043 30.3638 65.383 30.4082C65.4422 30.4377 65.4895 30.4673 65.5339 30.4999C65.6197 30.559 65.7321 30.6773 65.8799 30.8607C66.0278 31.0411 66.1225 31.1772 66.1757 31.2807L67.2523 33.3333ZM64.8507 33.0376H66.7643L65.9154 31.4138C65.8859 31.3546 65.8178 31.2452 65.6522 31.0441C65.4895 30.8459 65.4067 30.7661 65.3623 30.7365C65.2677 30.6684 65.105 30.5945 64.8862 30.5235L64.3774 30.3549L64.898 30.2366C65.176 30.1745 65.3978 30.0947 65.5516 30.003C65.7942 29.858 65.9894 29.6658 66.1284 29.4321C66.2644 29.2014 66.3354 28.9204 66.3354 28.5951C66.3354 28.2224 66.2437 27.906 66.0633 27.6516C65.8829 27.3943 65.6492 27.2227 65.3535 27.1281C65.0459 27.0305 64.5874 26.9802 63.99 26.9802H60.8252V33.0346H62.5259V30.4555H62.8483C63.0583 30.4555 63.2505 30.5146 63.4191 30.627C63.5463 30.7187 63.6794 30.8962 63.8273 31.1683L64.8507 33.0376ZM63.5138 29.5564H62.5289V27.9681H63.5493C63.9515 27.9681 64.2266 28.0331 64.3863 28.1662C64.5519 28.3052 64.6377 28.5034 64.6377 28.7519C64.6377 28.9234 64.5845 29.0772 64.4809 29.2103C64.3745 29.3464 64.2384 29.4321 64.0698 29.4647C63.7948 29.5268 63.6203 29.5564 63.5138 29.5564ZM62.8246 29.2606H63.5138C63.567 29.2606 63.6972 29.2458 64.0077 29.1778C64.1083 29.1571 64.1852 29.1097 64.2443 29.0328C64.3064 28.953 64.339 28.8613 64.339 28.7548C64.339 28.5951 64.2916 28.4768 64.194 28.3969C64.1231 28.3378 63.9515 28.2668 63.5463 28.2668H62.8217V29.2606H62.8246Z"
      :fill="color.text"
    />
    <path
      d="M35.5812 23.9456H59.5889L42.8187 6.03667C42.727 5.90949 42.6176 5.79118 42.4874 5.6847C41.9521 5.23217 41.115 4.93936 40.1745 4.93936C39.2339 4.93936 38.3969 5.23217 37.8616 5.6847C37.7344 5.79414 37.622 5.91245 37.5303 6.03667L20.7483 23.9456H32.4519L39.1008 16.5838L39.1866 16.5306C39.7042 16.2112 40.3608 16.2319 40.8577 16.5838L44.4839 20.21H39.1008L35.5812 23.9456Z"
      :fill="color.main"
    />
    <path
      d="M26.3413 23.1854H32.0112L38.5359 15.9893L38.9204 15.7793C39.5889 15.4156 40.3963 15.4215 41.0589 15.7941L41.4019 15.9864L46.2792 20.8636H39.512L37.2257 23.1499H53.842L41.2186 9.58296C40.5501 9.03578 39.5977 8.99142 38.882 9.47352L38.7193 9.58296L26.3413 23.1854Z"
      :fill="color.secondary"
    />
    <path
      d="M40 0C17.9089 0 0 9.98817 0 22.31C0 34.6318 17.9089 44.6199 40 44.6199C62.0911 44.6199 80 34.6318 80 22.31C80 9.98817 62.0911 0 40 0ZM40.1035 42.8571C20.6241 42.8571 4.83585 33.635 4.83585 22.2597C4.83585 10.8844 20.627 1.66223 40.1035 1.66223C59.58 1.66223 75.3741 10.8844 75.3741 22.2597C75.3741 33.635 59.583 42.8571 40.1035 42.8571Z"
      :fill="color.main"
    />
  </BaseSVG>
</template>

<script>
import BaseSVG from '../BaseSVG'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '@/../tailwind.config.js'

const { theme } = resolveConfig(tailwindConfig)

export default {
  name: 'LogoAndinor',
  components: { BaseSVG },
  props: {
    white: Boolean
  },
  computed: {
    color () {
      return {
        main: this.white ? theme.colors.white : theme.colors.brand,
        secondary: this.white ? theme.colors.transparent : theme.colors.secondary,
        text: this.white ? theme.colors.white : theme.colors.secondary
      }
    }
  }
}
</script>

<style lang="postcss" scoped>
path,
rect {
  @apply transition;
}
</style>
