import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import titleMixin from './mixins/titleMixin'

import '@/assets/css/tailwind.css'
import '@/assets/css/base.css'
import '@/assets/css/toast.scss'

import { extend } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
extend('required', {
  ...required,
  message: 'El campo no puede estar vacío'
})
extend('email', {
  ...email,
  message: 'Formato de correo inválido'
})

Vue.mixin(titleMixin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
