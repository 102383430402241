<template>
  <BaseSVG name="Linked In Icon" view-box="0 0 19 18">
    <g clip-path="url(#clip0)">
      <path d="M4.12148 1.97681C4.12148 3.0573 3.29966 3.92906 2.02399 3.92906C0.797396 3.92906 -0.0244245 3.0573 0.000107409 1.97681C-0.0244245 0.847203 0.797396 0 2.04853 0C3.29966 0 4.09694 0.847203 4.12148 1.97681ZM0.0982352 18V5.46385H3.98655V18H0.0982352Z" fill="white"/>
      <path d="M7.11424 9.45432C7.11424 7.89497 7.06518 6.55664 7.01611 5.45159H10.4015L10.5855 7.17055H10.6591C11.1743 6.37246 12.4499 5.16919 14.5229 5.16919C17.0865 5.16919 19 6.8636 19 10.5471V17.9755H15.1117V11.0505C15.1117 9.42976 14.5474 8.33699 13.1368 8.33699C12.0574 8.33699 11.4196 9.08597 11.162 9.79811C11.0639 10.0314 11.0026 10.412 11.0026 10.7681V18H7.11424V9.45432Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="19" height="18" fill="white"/>
      </clipPath>
    </defs>
  </BaseSVG>
</template>

<script>
import BaseSVG from '../BaseSVG'

export default {
  name: 'LinkedInIcon',
  components: { BaseSVG }
}
</script>
