<template>
  <BaseSVG name="Menu Icon" view-box="0 0 30 20">
    <rect width="30" height="4" fill="#000000" />
    <rect y="8" width="30" height="4" fill="#000000" />
    <rect y="16" width="30" height="4" fill="#000000" />
  </BaseSVG>
</template>

<script>
import BaseSVG from '../BaseSVG'

export default {
  name: 'MenuIcon',
  components: { BaseSVG }
}
</script>
