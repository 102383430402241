module.exports = {
  theme: {
    extend: {
      colors: {
        brand: '#DB610E',
        secondary: '#343C42'
      }
    }
  }
}
