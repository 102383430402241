<template>
  <div v-if="servicesRoutes && equipmentRoutes" class="navbar">
    <router-link to="/">
      <LogoAndinor />
    </router-link>
    <nav :class="{ show: showMenu }" class="menu">
      <div class="flex justify-between mb-8 sm:hidden">
        <router-link to="/" @click.native="showMenu = false">
          <LogoAndinor />
        </router-link>

        <CloseIcon class="w-6" @click.native="showMenu = false" />
      </div>

      <router-link
        class="menu-item"
        to="/nosotros"
        @click.native="showMenu = false"
      >
        Nosotros
      </router-link>

      <DropdownMenu
        :routes="servicesRoutes"
        text="Servicios"
        to="/servicios"
        @click.native="showMenu = false"
      />

      <DropdownMenu
        :routes="equipmentRoutes"
        text="Equipos"
        to="/equipos"
        @click.native="showMenu = false"
      />

      <router-link
        class="menu-item contact"
        to="/contacto"
        @click.native="showMenu = false"
      >
        Contacto
      </router-link>
    </nav>

    <MenuIcon class="w-8 sm:hidden" @click.native="showMenu = true" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import DropdownMenu from '@/components/DropdownMenu'
import LogoAndinor from '@/components/svg/logos/LogoAndinor'
import MenuIcon from '@/components/svg/icons/MenuIcon'
import CloseIcon from '@/components/svg/icons/CloseIcon'

export default {
  name: 'NavBar',
  components: { DropdownMenu, LogoAndinor, MenuIcon, CloseIcon },
  data () {
    return {
      showMenu: false
    }
  },
  computed: {
    ...mapState(['servicesRoutes', 'equipmentRoutes'])
  },
  created () {
    this.getServicesRoutes()
    this.getEquipmentRoutes()
    this.getProducts()
  },
  methods: {
    ...mapActions(['getServicesRoutes', 'getEquipmentRoutes', 'getProducts'])
  }
}
</script>

<style lang="postcss" scoped>
  .navbar {
    @apply flex items-center justify-between w-full max-w-screen-xl;
    @apply mx-auto p-5;
  }
  .menu {
    @apply fixed top-0 left-0 flex flex-col w-screen h-screen transform -translate-y-full z-50;
    @apply py-6 px-6 bg-white;
    @apply sm:relative sm:flex-row sm:items-center sm:justify-between sm:w-auto sm:h-auto sm:translate-y-0 sm:py-0 sm:px-0;
    @apply transition-all duration-300 ease-linear;
  }
  .menu.show {
    @apply translate-y-0;
  }
  .menu-item {
    @apply my-2 py-2 px-3 text-lg text-brand uppercase;
    @apply sm:ml-6 sm:my-0 sm:text-xs;
    @apply transition-colors duration-300 ease-linear;
  }
  .menu-item:hover,
  .menu-item.router-link-exact-active {
    @apply bg-brand text-white;
  }
  .menu-item.contact {
    @apply sm:border sm:border-brand;
  }
</style>
