import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const token = 'dd01dea09782bcbfc8f4a32124bef0'

const urlBuilder = (product) => {
  return product.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replaceAll(' ', '-')
}

export default new Vuex.Store({
  state: {
    servicesRoutes: undefined,
    equipmentRoutes: undefined,
    products: undefined,
    homeContent: undefined,
    aboutContent: undefined,
    servicesContent: undefined,
    equipmentContent: undefined,
    directoryMembers: undefined,
    contactInfo: undefined
  },
  getters: {
    productContent: (state) => (productPath) => {
      if (state.servicesRoutes && state.equipmentRoutes && state.products) {
        const routes = [...state.servicesRoutes, ...state.equipmentRoutes]
        let productId
        routes.forEach(route => {
          if (route.path === productPath) productId = route.id
        })
        return state.products.find(product => product.id === productId)
      }
    },
    services: (state) => {
      if (state.products) {
        const services = state.products.filter(product => product._modelApiKey === 'service')
        return services.map(service => {
          return {
            name: service.productName,
            image: service.heroImage.url,
            path: `/servicios/${urlBuilder(service.productName)}`
          }
        })
      }
    },
    equipment: (state) => {
      if (state.products) {
        const equipment = state.products.filter(product => product._modelApiKey === 'equipment')
        return equipment.map(machine => {
          return {
            name: machine.productName,
            image: machine.heroImage.url,
            path: `/equipos/${urlBuilder(machine.productName)}`
          }
        })
      }
    }
  },
  mutations: {
    setServicesRoutes (state, payload) {
      state.servicesRoutes = payload.map(product => {
        return {
          path: urlBuilder(product.productName),
          ...product
        }
      })
    },
    setEquipmentRoutes (state, payload) {
      state.equipmentRoutes = payload.map(product => {
        return {
          path: urlBuilder(product.productName),
          ...product
        }
      })
    },
    setProducts (state, products) {
      state.products = products
    },
    setHomeContent (state, content) {
      state.homeContent = content
    },
    setAboutContent (state, content) {
      state.aboutContent = content
    },
    setServicesContent (state, content) {
      state.servicesContent = content
    },
    setEquipmentContent (state, content) {
      state.equipmentContent = content
    },
    setDirectoryMembers (state, directoryMembers) {
      state.directoryMembers = directoryMembers
    },
    setContactInfo (state, content) {
      state.contactInfo = content
    }
  },
  actions: {
    fetchContent (context, payload) {
      fetch('https://graphql.datocms.com/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: payload.body
        }
      )
        .then(res => res.json())
        .then((res) => {
          // eslint-disable-next-line
          context.commit(payload.mutation, res.data[payload.contentType])
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getProducts (context) {
      fetch('https://graphql.datocms.com/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({ query: '{ allEquipment { _modelApiKey id productName heroImage { url } } allServices { _modelApiKey id productName heroImage { url } images { url } text title } }' })
        }
      )
        .then(res => res.json())
        .then((res) => {
          // eslint-disable-next-line
          context.commit('setProducts', [...res.data.allServices, ...res.data.allEquipment])
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getServicesRoutes (context) {
      const payload = {
        body: JSON.stringify({ query: '{ allServices { id productName } }' }),
        contentType: 'allServices',
        mutation: 'setServicesRoutes'
      }
      context.dispatch('fetchContent', payload)
    },
    getEquipmentRoutes (context) {
      const payload = {
        body: JSON.stringify({ query: '{ allEquipment { id productName } }' }),
        contentType: 'allEquipment',
        mutation: 'setEquipmentRoutes'
      }
      context.dispatch('fetchContent', payload)
    },
    getHomeContent (context) {
      const payload = {
        body: JSON.stringify({ query: '{ home { contactText heroImage { url } heroTitle leftText link linkButtonText linkImage { url } linkText linkTitle rightText rightTitle } }' }),
        contentType: 'home',
        mutation: 'setHomeContent'
      }
      context.dispatch('fetchContent', payload)
    },
    getAboutContent (context) {
      const payload = {
        body: JSON.stringify({ query: '{ about { heroImage { url } heroTitle leftColumn rightColumn } }' }),
        contentType: 'about',
        mutation: 'setAboutContent'
      }
      context.dispatch('fetchContent', payload)
    },
    getServicesContent (context) {
      const payload = {
        body: JSON.stringify({ query: '{ servicesPage { heroImage { url } heroTitle link linkButtonText linkImage { url } linkText linkTitle productText productTitle } }' }),
        contentType: 'servicesPage',
        mutation: 'setServicesContent'
      }
      context.dispatch('fetchContent', payload)
    },
    getEquipmentContent (context) {
      const payload = {
        body: JSON.stringify({ query: '{ equipmentPage { heroImage { url } heroTitle link linkButtonText linkImage { url } linkText linkTitle productText productTitle } }' }),
        contentType: 'equipmentPage',
        mutation: 'setEquipmentContent'
      }
      context.dispatch('fetchContent', payload)
    },
    getDirectoryMembers (context) {
      const payload = {
        body: JSON.stringify({ query: '{ allMiembroDeDirectorios { id image { url } name mainPosition secondaryPosition email } }' }),
        contentType: 'allMiembroDeDirectorios',
        mutation: 'setDirectoryMembers'
      }
      context.dispatch('fetchContent', payload)
    },
    getContactInfo (context) {
      const payload = {
        body: JSON.stringify({ query: '{ contactInfo { street streetNumber comune region mainNumber secondaryNumber email }}' }),
        contentType: 'contactInfo',
        mutation: 'setContactInfo'
      }
      context.dispatch('fetchContent', payload)
    }
  }
})
