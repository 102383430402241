<template>
  <footer class="footer">
    <div class="footer-wrapper">
      <div class="logo">
        <LogoAndinor white />
      </div>
      <div v-if="contactInfo" class="info">
        <p class="w-full pr-4 mb-4 text-xs font-light flex-0 whitespace-nowrap sm:w-1/2 sm:mb-10">
          <a class="block" href="#">
            <strong class="font-bold">Santiago</strong> <br />
            {{ contactInfo.street }} <br />
            {{ contactInfo.streetNumber }}, {{ contactInfo.comune }} <br />
            {{ contactInfo.region }}
          </a>
        </p>

        <p class="w-full mb-4 text-xs font-light flex-0 whitespace-nowrap sm:w-1/2 sm:mb-10">
          teléfono central
          <br />
          <a class="font-bold" :href="`tel:${ contactInfo.mainNumber.replaceAll(' ', '') }`">{{ contactInfo.mainNumber }}</a>
          <br />
          teléfono pago de facturas
          <br />
          <a class="font-bold" :href="`tel:${ contactInfo.secondaryNumber.replaceAll(' ', '') }`">{{ contactInfo.secondaryNumber }}</a>
          <br />
          e-mail
          <br />
          <a class="font-bold" :href="`mailto:${contactInfo.email}`">{{ contactInfo.email }}</a>
        </p>

        <div class="social-networks">
          <h2 class="text-xs font-semibold sm:mr-5">Síguenos</h2>
          <a href="#">
            <LinkedInIcon class="w-5 mr-4" />
          </a>
          <a href="#">
            <InstagramIcon class="w-5" />
          </a>
        </div>

        <p class="w-full text-xs whitespace-nowrap sm:w-1/2">
          {{ currentYear }} © Andinor Ltd.
          <br />Todos los derechos reservados.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LogoAndinor from '@/components/svg/logos/LogoAndinor'
import InstagramIcon from '@/components/svg/icons/InstagramIcon'
import LinkedInIcon from '@/components/svg/icons/LinkedInIcon'

export default {
  name: 'Footer',
  components: { LogoAndinor, InstagramIcon, LinkedInIcon },
  computed: {
    ...mapState(['contactInfo']),
    currentYear () {
      return new Date().getFullYear()
    }
  },
  created () {
    this.getContactInfo()
  },
  methods: {
    ...mapActions(['getContactInfo'])
  }
}
</script>

<style lang="postcss" scoped>
  .footer {
    @apply w-full py-6 px-5 bg-brand;
  }
  .footer-wrapper {
    @apply flex justify-between items-stretch max-w-screen-xl mx-auto;
  }
  .logo {
    @apply pr-8;
  }
  .logo svg {
    @apply w-20 sm:w-48 sm:h-24;
  }
  .info {
    @apply flex justify-start items-start flex-wrap flex-1 max-w-sm text-white;
  }
  .social-networks {
    @apply w-full mb-6 sm:flex sm:items-center sm:w-1/2;
  }
</style>
