<template>
  <div id="app">
    <div class="layout">
      <NavBar />
      <div class="content">
        <router-view />
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/layout/NavBar'
import Footer from '@/components/layout/Footer'

export default {
  name: 'Andinor',
  components: { NavBar, Footer }
}
</script>

<style lang="postcss" scoped>
  .layout {
    @apply flex flex-col items-stretch min-h-screen;
  }
  .top {
    @apply w-full;
  }
  .content {
    @apply flex-grow overflow-y-scroll flex flex-col w-full;
  }
  .content > main {
    @apply flex-grow;
  }
</style>
