<template>
  <BaseSVG name="Instagram Icon" view-box="0 0 20 20">
    <path d="M14.416 20H5.58405C2.50712 20 0 17.4929 0 14.416V5.58405C0 2.50712 2.50712 0 5.58405 0H14.416C17.4929 0 20 2.50712 20 5.58405V14.416C20 17.4929 17.4929 20 14.416 20ZM5.58405 1.61823C3.39601 1.61823 1.61823 3.39601 1.61823 5.58405V14.416C1.61823 16.604 3.39601 18.3818 5.58405 18.3818H14.416C16.604 18.3818 18.3818 16.604 18.3818 14.416V5.58405C18.3818 3.39601 16.604 1.61823 14.416 1.61823H5.58405Z" fill="white"/>
    <path d="M10.0057 15.2593C7.0997 15.2593 4.74072 12.9003 4.74072 9.99433C4.74072 7.08835 7.0997 4.72937 10.0057 4.72937C12.9117 4.72937 15.2706 7.08835 15.2706 9.99433C15.2592 12.9003 12.9003 15.2593 10.0057 15.2593ZM10.0057 6.359C7.99998 6.359 6.35896 7.98863 6.35896 10.0057C6.35896 12.0228 7.98859 13.6524 10.0057 13.6524C12.0114 13.6524 13.6524 12.0228 13.6524 10.0057C13.6524 7.98863 12.0114 6.359 10.0057 6.359Z" fill="white"/>
    <path d="M15.4872 5.33329C15.0314 5.33329 14.6667 4.96861 14.6667 4.51277C14.6667 4.05693 15.0314 3.69226 15.4872 3.69226C15.943 3.69226 16.3077 4.05693 16.3077 4.51277C16.3077 4.96861 15.9316 5.33329 15.4872 5.33329Z" fill="white"/>
  </BaseSVG>
</template>

<script>
import BaseSVG from '../BaseSVG'

export default {
  name: 'InstagramIcon',
  components: { BaseSVG }
}
</script>
