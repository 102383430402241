<template>
  <BaseSVG name="Close Icon" view-box="0 0 21 21">
    <rect
      width="24.3368"
      height="3.60229"
      transform="matrix(0.681782 -0.731555 0.819562 0.57299 0.508545 18.6707)"
      fill="black"
    />
    <rect
      width="24.3368"
      height="3.60229"
      transform="matrix(0.681782 0.731555 -0.819562 0.57299 3.91626 0.999207)"
      fill="black"
    />
  </BaseSVG>
</template>

<script>
import BaseSVG from '../BaseSVG'
export default {
  name: 'CloseIcon',
  components: { BaseSVG }
}
</script>
